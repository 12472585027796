<template>
  <div :class="$style.home">
    <div :class="$style.left">
      <List @change="listChange" />
    </div>
    <div :class="$style.right">
      <Detail :list-id="id" />
    </div>
  </div>
</template>

<script>
import Detail from './Detail.vue'
import List from './List.vue'

export default {
  name: 'Home',
  components: {
    List,
    Detail,
  },
  data() {
    return {
      id: '',
    }
  },
  methods: {
    listChange(id) {
      this.id = id
    },
  },
}
</script>

<style lang="less" module>
.home {
  display: flex;

  .left {
    width: 200px;
    margin-right: 20px;
  }

  .right {
    flex: 1;
  }
}
</style>
