<template>
  <div :class="$style.detail">
    <div :class="$style.left">
      <div v-for="(item, index) in list" :key="index" :class="[$style.box, $style.active]">
        <i :class="item.icon" />
        {{ item.name }}
      </div>
    </div>
    <div :class="$style.right">
      <a-row :class="$style.header">
        <a-col span="18" :class="$style.headerTitle">
          <span :class="$style.title">轮播图片</span>
          <BjSwitch v-model="data.is_swiper" :class="$style.switch" size="default" @change="setChange" />
        </a-col>
        <a-col span="6">
          <BjButton
            v-permission="'app.community.channel-swiper.create'"
            type="primary"
            :tooltips="data.list.length > 9 ? '轮播图片最多设置10条，超过10条将不能再次添加。' : ''"
            :disabled="data.list.length > 9"
            class="pull-right"
            @click="onAdd()"
          >
            <i class="ri-add-line left" /> 添加轮播
          </BjButton>
        </a-col>
      </a-row>
      <a-table
        :class="$style.table"
        :loading="loading"
        :columns="columns"
        :data-source="data.list"
        :pagination="false"
        row-key="id"
      >
        <template #cover="item">
          <img :class="$style.cover" :src="item.image.cover" alt="" />
        </template>
        <template #action="item">
          <bj-link v-permission="'app.community.channel-swiper.edit'" type="primary" @click="onEdit(item)">
            编辑
          </bj-link>
          <a-popconfirm placement="topLeft" title="确定要删除轮播吗？" @confirm="onDelete(item)">
            <bj-link v-permission="'app.community.channel-swiper.delete'" type="danger"> 删除 </bj-link>
          </a-popconfirm>
        </template>
      </a-table>
    </div>
    <bj-modal :title="title" :visible="visible" :body-style="{ height: '400px' }" @ok="handleOk" @cancel="handleCancel">
      <BjForm :class="$style.modal">
        <a-row class="mb-20">
          <a-col span="6" class="line-40">
            <span class="required">*</span>
            轮播标题
          </a-col>
          <a-col span="18">
            <BjInput v-model="add.title" placeholder="请输入轮播标题" />
          </a-col>
        </a-row>
        <a-row class="mb-20">
          <a-col span="6" class="line-40">
            <span class="required">*</span>
            轮播图片
          </a-col>
          <a-col span="18">
            <selectMaterial :data="add.image" :allow="['image']" @change="coverChange" />
            <p class="tip mt-10">轮播图片建议尺寸700px*240px；大小不超过2M；格式支持JPG、PNG。</p>
          </a-col>
        </a-row>
        <BjValidationItem rules="required" label-align="left" label="跳转链接" v-bind="layout">
          <BjButton class="btn-default" @click="onAddLink()">
            <i class="ri-link left" />
            跳转链接
          </BjButton>
          <p v-if="add.link" :class="$style.link">{{ add.link.label }}</p>
          <p class="tip mt-10">请选择点击轮播图片需要跳转的链接。</p>
        </BjValidationItem>
      </BjForm>
    </bj-modal>
    <chooseLink :visible.sync="linkVisible" :data="add.link" @change="getLink" />
  </div>
</template>

<script>
import _ from 'lodash'

import chooseLink from '@/components/chooseLink'
import selectMaterial from '@/components/selectMaterial'
import { communityService } from '@/service'

const service = new communityService()

export default {
  name: 'Detail',
  components: {
    selectMaterial,
    chooseLink,
  },
  props: {
    listId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      layout: {
        labelCol: { span: 6 },
        wrapperCol: { span: 18 },
      },
      list: [{ name: '轮播图片', id: 'swiper', icon: 'ri-layout-column-line' }],
      data: {
        list: [],
        is_swiper: 0,
      },
      loading: false,
      visible: false,
      linkVisible: false,
      title: '',
      id: null,
      add: {
        title: '',
        image: {},
        link: {},
      },
    }
  },
  computed: {
    columns() {
      return [
        {
          title: '轮播标题',
          dataIndex: 'title',
          ellipsis: true,
        },
        {
          title: '轮播图片',
          scopedSlots: {
            customRender: 'cover',
          },
        },
        {
          title: '跳转链接',
          dataIndex: 'link',
          customRender: value => value.label,
        },
        {
          title: '添加时间',
          dataIndex: 'created_at',
        },
        {
          title: '管理',
          width: 150,
          scopedSlots: {
            customRender: 'action',
          },
        },
      ]
    },
  },
  watch: {
    listId(val) {
      if (val) {
        this.getInfo()
      }
    },
  },
  methods: {
    async getInfo() {
      try {
        const { data } = await service.getChannelSwiper({
          channel_id: this.listId,
        })
        this.data = data
      } catch (e) {}
    },
    async onDelete(item) {
      try {
        await service.swiperDeleteChannel({
          id: item.id,
        })
        this.$message.success('删除成功')
        this.getInfo()
      } catch (e) {}
    },
    onAdd() {
      this.visible = true
      this.title = '添加轮播'
      this.id = null
      this.add = {
        title: '',
        image: {},
        link: null,
      }
    },
    onEdit(item) {
      this.title = '修改轮播'
      this.add = _.cloneDeep(item)
      this.id = item.id
      this.visible = true
    },
    onAddLink() {
      this.linkVisible = true
    },
    getLink(data) {
      this.add.link = data
    },
    coverChange(data) {
      this.add.image = data[0]
    },
    async setChange() {
      try {
        await service.swiperSetChannel({
          channel_id: this.listId,
          is_swiper: this.data.is_swiper,
        })
        this.$message.success('修改成功')
      } catch (e) {}
    },
    async handleOk() {
      try {
        this.id
          ? await service.editSwiperChannel({
              channel_id: this.listId,
              ...this.add,
            })
          : await service.addSwiperChannel({
              channel_id: this.listId,
              ...this.add,
            })
        this.$message.success(this.id ? '修改成功' : '添加成功')
        this.visible = false
        this.getInfo()
      } catch (e) {}
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.detail {
  display: flex;
  width: 100%;
  height: 100%;
  padding: 20px;
  background: #fff;

  .left {
    width: 180px;
    height: 100%;
    padding-right: 20px;
    border-right: 1px solid #eee;

    .box {
      width: 160px;
      height: 44px;
      line-height: 44px;
      border-radius: 6px;

      i {
        margin-right: 8px;
        margin-left: 12px;
        font-size: 20px;
        vertical-align: -4px;
      }
    }

    .box:hover {
      color: @primary-color;
      background: #fafafa;

      i {
        color: @primary-color;
      }
    }

    .active {
      color: @primary-color;
      background: #fafafa;

      i {
        color: @primary-color;
      }
    }
  }

  .right {
    flex: 1;
    padding-left: 20px;

    .title {
      color: #000;
      font-size: 16px;
    }

    .header {
      margin-bottom: 20px;

      &-title {
        line-height: 32px;
      }

      .switch {
        position: absolute;
        top: 5px;
        margin-left: 10px;
      }
    }

    .table {
      .cover {
        width: 88px;
        height: 30px;
      }
    }
  }
}

.modal {
  :global {
    .ant-form-item {
      margin-bottom: 0;
    }
  }
}
</style>
