<template>
  <div :class="$style.list">
    <p :class="$style.title">频道列表</p>
    <i v-permission="'app.community.channel-create'" :class="$style.add" class="ri-add-circle-line" @click="onAdd()" />
    <Draggable v-model="data" filter=".forbid" :move="onMove" @end="onEnd">
      <transition-group>
        <div
          v-for="item in data"
          :key="item.id"
          :class="[$style.box, item.is_default ? 'forbid' : null, active === item.id ? $style.active : null]"
          @click="onChoose(item)"
        >
          <div v-if="!item.is_default" :class="$style.drap">
            <img :src="oss + '/manage/icon/default/sort.png'" alt="" />
          </div>
          <img :class="$style.cover" :src="item.icon.cover" />
          <div :class="$style.name" class="ellipsis">
            {{ item.name }}
          </div>
          <i v-if="item.is_default" :class="$style.default" class="ri-pushpin-line" />
          <a-dropdown placement="bottomRight" :trigger="['hover']">
            <i class="ri-more-fill primary" :class="$style.drop" />
            <a-menu slot="overlay" class="list-drop">
              <a-menu-item>
                <BjLink v-permission="'app.community.channel-edit'" @click="onEdit(item)">
                  <i class="ri-edit-2-line" />
                  修改频道
                </BjLink>
              </a-menu-item>
              <a-menu-item v-if="!item.is_default">
                <a-popconfirm title="确定要删除频道吗？" @confirm="onDelete(item)">
                  <BjLink v-permission="'app.community.channel-delete'">
                    <i class="ri-delete-bin-7-line" />
                    删除频道
                  </BjLink>
                </a-popconfirm>
              </a-menu-item>
            </a-menu>
          </a-dropdown>
        </div>
      </transition-group>
    </Draggable>
    <bj-modal :title="title" :visible="visible" :body-style="{ height: '400px' }" @ok="handleOk" @cancel="handleCancel">
      <BjForm>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            频道名称
          </a-col>
          <a-col span="18">
            <BjInput v-model="add.name" placeholder="请输入频道名称" />
          </a-col>
        </a-row>
        <a-row>
          <a-col span="6">
            <span class="required">*</span>
            频道图标
          </a-col>
          <a-col span="18">
            <selectMaterial :data="add.icon" :allow="['image']" @change="coverChange" />
            <div class="tip mt-10">频道图标建议尺寸200px*200px；大小不超过2M；格式支持JPG、PNG。</div>
          </a-col>
        </a-row>
      </BjForm>
    </bj-modal>
  </div>
</template>

<script>
import _ from 'lodash'
import Draggable from 'vuedraggable'

import selectMaterial from '@/components/selectMaterial'
import { communityService } from '@/service'

const service = new communityService()

export default {
  name: 'List',
  components: {
    Draggable,
    selectMaterial,
  },
  data() {
    return {
      data: [],
      title: null,
      visible: false,
      add: {
        name: null,
        icon: {},
      },
      id: null,
      active: null,
    }
  },
  watch: {
    active() {
      this.onChange()
    },
  },
  created() {
    this.getList(true)
  },
  methods: {
    onChoose(item) {
      this.active = item.id
    },
    onAdd() {
      this.add = {
        name: null,
        icon: {},
      }
      this.id = null
      this.visible = true
      this.title = '添加频道'
    },
    onEdit(item) {
      this.visible = true
      this.title = '修改频道'
      this.id = item.id
      this.add = _.cloneDeep(item)
    },
    async onDelete(item) {
      try {
        await service.deleteChannel({
          id: item.id,
        })
        this.$message.success('删除成功')
        if (this.active === item.id) {
          // 写个if
          this.getList(true)
        } else {
          this.getList()
        }
      } catch (e) {}
    },
    onChange() {
      this.$emit('change', this.active)
    },
    async getList(type) {
      try {
        const { data } = await service.getChannelList()
        this.data = data
        if (type) {
          this.active = data[0].id
        }
      } catch (e) {}
    },
    onMove(e) {
      if (e.relatedContext.element.is_default) {
        return false
      }
    },
    onEnd() {
      this.sortSave()
    },
    async sortSave() {
      try {
        let ids = this.data.map(item => item.id)
        await service.sortChannel({
          ids: ids,
        })
      } catch (e) {}
    },
    coverChange(data) {
      this.add.icon = data[0]
    },
    async handleOk() {
      try {
        this.id
          ? await service.saveChannelEdit(this.add)
          : await service.saveChannelAdd({
              ...this.add,
              id: this.id,
            })
        this.$message.success(this.id ? '修改成功' : '添加成功')
        this.visible = false
        this.getList()
      } catch (e) {}
    },
    handleCancel() {
      this.visible = false
    },
  },
}
</script>

<style lang="less" module>
.list {
  position: relative;
  width: 100%;
  min-height: 100%;
  padding: 20px;
  background: #fff;

  p {
    margin-bottom: 0;
  }

  .title {
    margin-bottom: 14px;
    color: #000;
    font-size: 16px;
  }

  .add {
    position: absolute;
    top: 17px;
    right: 20px;
    color: @primary-color;
    font-size: 20px;
  }

  .box {
    position: relative;
    display: flex;
    align-items: center;
    width: 160px;
    height: 44px;
    margin-bottom: 2px;
    font-size: 14px;
    line-height: 44px;
    border-radius: 6px;
    cursor: pointer;

    .drap {
      position: absolute;
      top: 0;
      left: -20px;
      width: 20px;
      height: 100%;
      opacity: 0;

      img {
        width: 20px;
        height: 20px;
      }
    }

    .drop {
      display: none;
      margin: 0 10px;
    }

    .default {
      margin: 0 10px;
      color: #bbb;
    }

    .cover {
      width: 20px;
      height: 20px;
      margin: 0 8px 0 12px;
      object-fit: cover;
    }

    .name {
      flex: 1;
    }
  }

  .active {
    color: @primary-color;
    background: #fafafa;
  }

  .box:hover {
    background: #fafafa;
  }

  .box:hover .drap {
    opacity: 1;
  }

  .box:hover .drop {
    display: inline-block;
  }

  .box:hover .default {
    opacity: 0;
  }
}
</style>
